// @flow
import React from 'react';
import Layout from '../components/layout';
import Link from '../components/link';
import GlossaryLink from '../components/glossary-link';
import type { FrontMatter, ImageType } from '../utils/types';
import { withFrontMatter } from '../components';
import Image from '../components/image';
import { graphql } from 'gatsby';

type Props = {
  frontMatter: FrontMatter,
  data: {
    bladder: ImageType,
    disposable: ImageType,
    nalgene: ImageType,
    waterBottles: ImageType,
    platypus: ImageType,
    sportsDrink: ImageType,
  },
};

class Canyoneering_WaterBottle extends React.Component<Props> {
  render() {
    const { frontMatter, data } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>
          Boring review of every type of water bottle I have seen in canyons. I
          haven't tried everything, but I have seen all of these succeed or
          fail.
        </p>
        <Image image={data.waterBottles} />
        <hr />
        <h2>Cheap disposable water bottles</h2>
        <Image image={data.disposable} />
        <p>
          If you see someone with a water bottle like this, it usually means
          they are brand new to canyoneering and don't know what they are doing
          yet.
        </p>
        <p>
          Theoretically these should be good water bottles. They are lightweight
          and can fit in the corners of a <GlossaryLink>backpack</GlossaryLink>{' '}
          where other things might not. Unfortunately they are quite flimsy and
          I can't trust them to survive in more than one or two canyons. They
          also can't hold a lot of water. The water bottles I used in my first
          few canyons, for example, held 16 oz each. To get a full gallon for a
          long day I would have needed 8 of those bottles.
        </p>
        <hr />
        <h2>Water bladders</h2>
        <Image image={data.bladder} />
        <p>
          Some people love Camelbak-style water bladders and some people have
          used them for more than five minutes. Just kidding - some people have
          success with them. For the right canyon they can be perfectly fine.
        </p>
        <p>
          Let's start with the good things because it'll be quick. The key
          advantage with water bladders are that the water is easily accessible
          without taking off your backpack. And when empty they take up almost
          no space. And they're pretty light for the amount of water they hold.
          That's it - thus endeth the good.
        </p>
        <p>
          Now the bad. Deep breath. They break easily and the bite values always
          seem to leak or go missing. Also if your pack is full the pressure can
          push the water out.
        </p>
        <p>
          It's difficult to know how much water is in the bladder just by
          looking at it so it's tougher to know how much you have drank versus
          how much you have left. After swimming through nasty, smelly water, I
          always hesitate to drink from them because I don't know how much of
          the nasty mixed with the clean. You also have to have a compatible
          pack - many packs are compatible but not all of them.
        </p>
        <p>
          If the canyon has a long approach, never gets skinny, and doesn't have
          gross swims, and you don't stuff your pack too full, it should be
          fine. <Link to="http://ropewiki.com/Behunin_Canyon">Behunin</Link>{' '}
          comes to mind.
        </p>
        <hr />
        <h2>Platypus water bags</h2>
        <Image image={data.platypus} />
        <p>
          These bags combine all the disadvantages of disposable water bottles
          with all the disadvantages of a water bladder. For the year or so
          after they were released, I would actually pick up broken ones dropped
          in canyons almost as often as disposable water bottles. I know only
          one person who bought them specifically to test them for canyoneering.
          They didn't last one canyon. Avoid.
        </p>
        <hr />
        <h2>Nalgene bottles</h2>
        <Image image={data.nalgene} />
        <p>
          I brought these with me for a long time not knowing how terrible they
          were. Then I still brought them for a long time knowing how terrible
          they are: heavy, prone to shattering when dropped, and expensive. One
          skilled canyoneer says they are the greatest scam in mountaineering.
          Gatorade/Powerade bottles are superior in all but a few ways.
        </p>
        <p>
          The good qualities they have are they come with a loop so it's easy to
          clip them to the outside of your pack or hook your finger around it
          when carrying it. The ones with a wide opening are slightly easier to
          pour water into. They are also easier to stir the contents with a
          spoon for the zero times you will ever do that.
        </p>
        <hr />
        <h2>Disposable sports drink bottles</h2>
        <Image image={data.sportsDrink} />
        <p>
          Most water containers follow the "lighter implies flimsy, heavy
          implies tough" rule. Disposable sports drink bottles, specifically the
          one liter Gatorade and Powerade bottles, offer a great balance of
          toughness and light weight. They are the best choice I have found for
          canyoneering.
        </p>
        <p>
          The advantages are they are significantly lighter than comparably
          tough containers such as Nalgene. They are significantly less
          expensive as well. If you drop it it will bounce rather than crack
          like a Nalgene bottle.
        </p>
        <p>
          Ready for the drawbacks? They don't have a loop so it can be harder to
          carry it while hiking. They always come with a wrapper that will
          inevitably peel off while leaving the sticky glue behind. Warm water
          and a little scrubbing will wash that off, btw.
        </p>
        <p>
          Of all the disposable sports drink bottles my favorite is the
          Strawberry Lemonade Powerade bottle. Then the Watermelon Strawberry
          Wave bottle. I don't like their Blue Raspberry Cherry bottle. I prefer
          the shape of Powerade bottles over the Gatorade bottles because they
          are skinnier and seem to fit better in the nooks and crannies of a
          full pack.
        </p>
        <p>
          Oh, and to fix the "no loop" drawback I created a little loop using
          3mm nylon accessory cord. It cinches tightly on the bottle and allows
          me to hold it or clip it to the front of my pack. There's a picture of
          it at the top of the page. Haven't quite figured out a good enough set
          of knots to be perfectly happy but it's way better in every way than a
          Nalgene bottle.
        </p>
        <p>
          One last drawback is that Powerade recently redesigned their bottles
          and they now only hold 28 ounces instead of 32.{' '}
          <Link to="https://www.reddit.com/r/assholedesign/comments/eta5ps/powerade_is_using_shrinkflation_by_replacing/">
            Super annoying
          </Link>
          . Watch for it and don't accidentally think you have more water than
          you actually do.
        </p>
        <h2>Conclusion</h2>
        <p>
          Get the disposable sports drink bottles. Or do whatever. I won't judge
          you unless you have those terrible Platypus bags.
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('water-bottle')(Canyoneering_WaterBottle);

export const query = graphql`
  query {
    bladder: file(
      relativePath: { eq: "images/canyoneering/water-bottle/bladder.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    disposable: file(
      relativePath: { eq: "images/canyoneering/water-bottle/disposable.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    nalgene: file(
      relativePath: { eq: "images/canyoneering/water-bottle/nalgene.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    waterBottles: file(
      relativePath: { eq: "images/canyoneering/water-bottle/water-bottles.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    platypus: file(
      relativePath: { eq: "images/canyoneering/water-bottle/platypus.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    sportsDrink: file(
      relativePath: { eq: "images/canyoneering/water-bottle/sports-drink.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
